import React, { Component, Fragment } from "react";
import getStartedPdf from "../TopMenu/Getting_Started.pdf";

import { Link } from "react-router-dom";
import onClickOutside from "react-onclickoutside";
import { Icon } from "semantic-ui-react";
import AppContext from "../../../utils/AppContext";

import "./MobileMenuIcon.css";

class MobileMenuIcon extends Component {
    state = {
        showPopup: false,
        showFeatures: false,
        showDashboard: false,
    };

    togglePopup = () => {
        this.setState({ showPopup: !this.state.showPopup });
    };

    handleClickOutside = () => {
        this.setState({ showPopup: false });
    };

    toggleFeatures = () => {
        this.setState({ showFeatures: !this.state.showFeatures });
    };


    render() {
        return (
            <AppContext.Consumer>
                {(context) => (
                    <div className={`mobile-menu-icon__component ${this.state.showPopup ? "active" : ""}`}>
                        <div className="icon menu-trigger" onClick={this.togglePopup} />
                        <div className={"popup-container " + this.state.showPopup}>
                            <div className="icon menu-trigger" onClick={this.togglePopup} />

                            <Link to={"/"} role="link">
                                <Icon name="home" />
                                Home
                            </Link>

                            <Link to="/tag-manager" role="link" rel="nofollow">
                                <Icon name="tag-manager" />
                                Tag Manager
                            </Link>
                            <Link to="/dashboard/tag-health" role="link" title="Tag Health Dashboard">
                                <Icon name="dashboard" />
                                Tag Health
                            </Link>
                            <Link to="/dashboard" role="link" title="Environment Summary Dashboard">
                                <Icon name="grid layout" />
                                Cost Summary
                            </Link>
                            <Link to="/dashboard/resources" role="link" title="Resource View Dashboard">
                                <Icon name="list" />
                                Resources
                            </Link>

                            {/* only show the AVD link if the user can see/use the AVD feature */}
                            {context.state.avdAvailable === true &&
                                <Link to="/avd" role="link" title="Cloud Clarity AVD costs tool">
                                    <Icon name="desktop" />
                                    AVD
                                </Link>
                            }
                            <hr />

                            <Link to="/purchasing" role="link" title="CloudClarity Purchasing Help">
                                <Icon name="law" />
                                Purchasing Help
                            </Link>
                            <a href="https://portal.azure.com/#create/cubesys.cloud_clarity" role="link" target="_blank">
                                <Icon name="microsoft" />
                                Subscribe
                            </a>
                            <a href="https://outlook.office365.com/owa/calendar/CloudClarity@cubesys.com.au/bookings/" role="link" target={"_blank"}>
                                <Icon name="request-a-demo" />
                                Request a Demo
                            </a>
                            <Link to="/pricing" role="link" title="CloudClarity Pricing">
                                <Icon name="usd icon" />
                                Pricing
                            </Link>
                            <a
                                onClick={() => {
                                    window.open(getStartedPdf);
                                }}
                                role="link"
                                target={"_blank"}
                                style={{ cursor: "pointer" }}
                            >
                                <Icon name="compass outline" />
                                Getting Started
                            </a>
                            <a className="hide-mobile" onClick={() => window.open("https://www.linkedin.com/company/cloudclarity")} title="CloudClarity LinkedIn" rel="nofollow">
                                <Icon name="linkedin" title="Follow us on linkedin" />
                                Follow Us
                            </a>
                            <a className="hide-mobile" href="https://medium.com/@cloudclarity" title="CloudClarity Medium blog" rel="nofollow">
                                <Icon name="medium" title="Blog" />
                                Blog
                            </a>
                            <hr />
                            <div className={this.state.showFeatures ? "features active" : "features"}>
                                <a
                                    onClick={() => {
                                        this.toggleFeatures();
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <Icon name="book" />
                                    <span>Explore Features</span>
                                    <Icon style={{ right: "12px", left: "inherit" }} name={this.state.showFeatures ? "chevron up" : "chevron down"} />
                                </a>
                                {this.state.showFeatures && (
                                    <Fragment>
                                        <Link to="/feature/tags" role="link" title="Tag Manager Feature">
                                            <Icon name="tag-manager" />
                                            Tag Manager
                                        </Link>
                                        <Link to="/feature/tag-health" role="link" title="Tag Health Feature">
                                            <Icon name="file alternate" />
                                            Tag Health
                                        </Link>
                                        {/* <Link to="/feature/cost-explorer" role="link" title="Cost Explorer Feature">
                                            <Icon name="cost-explorer" />
                                            Cost Explorer
                                        </Link> */}
                                        {/* <Link to="/feature/cost-summary" role="link" title="Cost Summary Feature">
                                            <Icon name="cost-summary" />
                                            Chargebacks
                                        </Link> */}

                                        <Link to="/feature/dashboard" role="link" title="Personalised Dashboard Feature">
                                            <Icon name="dashboard" />
                                            Cost Summary
                                        </Link>

                                        {/* obselete, may remove completely in the future
                                        <Link to="/feature/avd" role="link" title="Cloud Clarity for AVD feature">
                                            <Icon name="desktop" />
                                            AVD
                                        </Link>
                                        */}

                                        {/* <Link to="/feature/arm-visualiser" role="link" title="Arm Visualiser Feature">
                                            <Icon name="map outline icon" />
                                            ARM Visualiser
                                        </Link> */}
                                    </Fragment>
                                )}
                            </div>
                            <Link to="/about" role="link" title="About CloudClarity">
                                <Icon name="info circle icon" />
                                About
                            </Link>

                            <Link to="/faq" title="Frequently Asked Questions">
                                <Icon name="help" />
                                Frequently Asked Questions
                            </Link>
                            <a href="mailto:info@tagmanager.app" role="link">
                                <Icon name="mail outline" />
                                Contact Us
                            </a>

                            <Link to="/support" title="Support">
                                <Icon name="support" />
                                Support
                            </Link>

                            <Link to="/feedback" title="Feedback">
                                <Icon name="edit outline" />
                                Feedback
                            </Link>

                            <Link to="/legal" title="legal">
                                <Icon name="legal" />
                                Legal
                            </Link>
                        </div>
                    </div>
                )}
            </AppContext.Consumer>
        );
    }
}

export default onClickOutside(MobileMenuIcon);
